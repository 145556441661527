import React, { ReactElement } from 'react';

import './barChartLoading.css';

export default function BarCharLoading(): ReactElement {
  return (
    <div>
      {Array(8).fill(null).map((_, index) => (
        <div key={index} />
      ))}
    </div>
  );
}
