import React from 'react';
import { connect } from 'react-redux';
import { isNil, get, isEmpty, isEqual } from 'lodash';

import { Overlay } from 'src/common-ui/index';
import CompanionListView, {
  Props as CompanionProps,
} from 'src/common-ui/components/CompanionListView/CompanionListView';

import { listPairStyle, companionStyles } from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import Subheader from 'src/components/Subheader/Subheader.container';
import { OvertimeView } from 'src/pages/AssortmentBuild/OvertimeView/OvertimeView';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ASSORTMENT, ASSORTMENT_BUILD_FILTER_ALL_WARNING } from 'src/utils/Domain/Constants';
import { CompanionOvertimeConfig } from '../OvertimeView.types';
import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { getHeaderTextFromState } from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { resolvePath } from 'src/cdn';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import { NestedOvertimeComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { CompanionDataLookup, parseCompanionListViewConfig } from 'src/utils/Component/ListView';
import { companionDataParse } from 'src/components/ListGridPair/ListGridPair.utils';
import { FavoriteListItemStorage, FavoriteResponseItem } from 'src/components/Subheader/Favorites/Favorites.types';
import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { isViewDefnLoaded, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import {
  requestTenantConfig,
  receiveTenantConfig,
  setSelectedId,
  updateTenantConfig,
} from 'src/pages/AssortmentBuild/OvertimeView/StyleByLevel/StyleByLevelOvertime.slice';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { Option } from 'src/components/Configure/ConfigureModal';
import {
  ViewConfiguratorModalProps,
  CompanionFavoriteData,
} from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';

const noImage = resolvePath(noImagePath);

export interface StyleByLevelOvertimeOwnProps extends z.infer<typeof NestedOvertimeComponentProps> {}

export interface StyleByLevelOvertimeDispatchProps {
  onShowView: () => void;
  onRefresh: (id: string) => void;
  onConfigUpdate: (config: any) => void;
}

interface StyleByLevelOvertimeValueProps {
  customHeader: string;
  subheaderState: SubheaderSlice;
  aggBys: string[];
  configLoaded: boolean;
  dataLoaded: boolean;
  data?: BasicPivotItem[];
  companionData: BasicPivotItem[];
  viewDefn: CompanionOvertimeConfig;
  unmodifiedViewDefn?: CompanionOvertimeConfig;
  companionDefn?: CompanionDataLookup;
  selectedCompanionId: string;
  configureSelections?: Option[];
  defaultConfigureSelections?: Option[];
  defaultCompanionSortField: string;
}

export interface StyleByLevelOvertimeProps
  extends StyleByLevelOvertimeOwnProps,
    StyleByLevelOvertimeValueProps,
    StyleByLevelOvertimeDispatchProps {}

interface State {
  companionView?: {
    sortDirection?: SortByDirection;
    sortBy?: string;
  };
  companionCollapsed?: boolean;
  configureIsOpen: boolean;
}

function dispatchToProps(
  dispatch: AppThunkDispatch,
  { defns }: StyleByLevelOvertimeOwnProps
): StyleByLevelOvertimeDispatchProps {
  return {
    async onShowView() {
      dispatch(requestTenantConfig());
      const configResponse = await container.tenantConfigClient.getTenantViewDefnsWithFavorites<
        CompanionOvertimeConfig
      >({
        defnIds: [defns.view[0]],
        appName: ASSORTMENT,
      });
      const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
        defns.view[0],
        (configResponse[1] as unknown) as FavoriteResponseItem[],
        dispatch,
        (configResponse[0] as unknown) as TenantConfigViewData
      );
      const configWithFavorites = isNil(localConfig)
        ? configResponse[0]
        : ((localConfig.config as unknown) as CompanionOvertimeConfig);

      const [, ...moreDefns] = defns.view;
      const moreConfigResponses = await container.tenantConfigClient.getTenantViewDefnsWithFavorites({
        defnIds: moreDefns,
        appName: ASSORTMENT,
      });
      dispatch(
        receiveTenantConfig({
          viewDefn: configWithFavorites,
          unmodifiedViewDefn: configResponse[0],
          companionDefn: parseCompanionListViewConfig(moreConfigResponses[0]),
          defaultCompanionSortField: configWithFavorites.companionGrid.sortConfig.defaultSelection,
        })
      );
    },
    onRefresh(selectedId: string) {
      dispatch(setSelectedId(selectedId));
    },
    onConfigUpdate(config: any) {
      dispatch(updateTenantConfig(config as CompanionOvertimeConfig));
    },
  };
}
function mapStateToProps(state: AppState, ownProps: StyleByLevelOvertimeOwnProps): StyleByLevelOvertimeValueProps {
  const customHeader = getHeaderTextFromState(state, true);
  const viewState = state.pages.hindsighting.styleByLevelOvertime;
  const {
    viewDataState,
    companionDataState,
    viewDefnState,
    data,
    companionData,
    viewDefn,
    unmodifiedViewDefn,
    companionDefn,
    selectedCompanionId,
    configureSelections,
    defaultConfigureSelections,
    defaultCompanionSortField,
  } = viewState;
  const configLoaded = isViewDefnLoaded(viewDefnState) && !isEmpty(viewDefn);
  const dataLoaded = isDataLoaded(viewDataState) && isDataLoaded(companionDataState);

  return {
    ...ownProps,
    configLoaded,
    dataLoaded,
    customHeader,
    subheaderState: state.subheader,
    data,
    companionData,
    viewDefn,
    companionDefn,
    unmodifiedViewDefn,
    selectedCompanionId,
    configureSelections,
    defaultConfigureSelections,
    defaultCompanionSortField,
    // FIXME: move me to ownProps dataApi or something
    aggBys: ['style', 'stylecolor'],
  };
}

class StyleByLevelOvertime extends React.Component<StyleByLevelOvertimeProps, State> {
  constructor(props: StyleByLevelOvertimeProps) {
    super(props);
    this.state = {
      configureIsOpen: false,
      companionView: {
        sortDirection: 'desc',
      },
    };
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: Readonly<StyleByLevelOvertimeProps>): void {
    if (!isEqual(prevProps.subheaderState.favoritesList, this.props.subheaderState.favoritesList)) {
      const activeFavorite: FavoriteResponseItem | undefined = this.props.subheaderState?.favoritesList?.find(
        (x) => x.active === true
      );
      if (activeFavorite && activeFavorite.jsonBlob && activeFavorite.jsonBlob.companionData) {
        const favoriteSortField = activeFavorite.jsonBlob.companionData.companionSortField;
        const favoriteSortDirection: SortByDirection = activeFavorite.jsonBlob.companionData.companionSortDirection;
        const favoriteCompIsCollapsed = activeFavorite.jsonBlob.companionData.companionCollapsed;

        this.setState({
          companionView: {
            sortDirection: favoriteSortDirection,
            sortBy: favoriteSortField,
          },
          companionCollapsed: favoriteCompIsCollapsed,
        });
      } else {
        this.setState({
          companionView: {
            sortBy: this.props.viewDefn?.companionGrid?.sortConfig.defaultSelection || '',
            sortDirection: 'desc',
          },
          companionCollapsed: false,
        });
      }
    }
  }

  renderCompanionView = (sortDirection: SortByDirection | undefined, sortBy: string | undefined) => {
    const { companionView } = this.state;
    const { viewDefn, companionDefn, companionData, selectedCompanionId, defaultCompanionSortField } = this.props;
    if (
      isNil(companionData) ||
      isNil(companionDefn) ||
      isNil(companionView) ||
      isNil(viewDefn) ||
      isNil(sortDirection) ||
      isNil(sortBy)
    ) {
      return null;
    }

    const data = companionDataParse(companionData, companionDefn, sortDirection, sortBy).filter(
      (i) => i.title !== '' && i.title != null
    );
    const { options: sortOptions } = viewDefn.companionGrid.sortConfig;
    const sortSelection = sortOptions.findIndex((option) => {
      return option.dataIndex === sortBy;
    });
    const defaultSelectionIndex = sortOptions.findIndex((option) => option.dataIndex === defaultCompanionSortField);
    const currentSelectionInd = data.findIndex((option) => option.id === selectedCompanionId);
    const selectedIndex = currentSelectionInd >= 0 ? currentSelectionInd : 0;

    const companionProps: CompanionProps = {
      defaultSelection: sortSelection > -1 ? sortSelection : defaultSelectionIndex,
      initialSortDirection: sortDirection,
      selectedIndex,
      sortOptions,
      label: viewDefn.companionGrid.label,
      data,
      className: companionStyles,
      dataLookup: companionDefn,
      onListItemClicked: (classId: string) => {
        if (companionView == null || selectedCompanionId === classId) {
          return;
        }
        this.props.onRefresh(classId);
      },
      onChangeDirection: (direction) => {
        if (companionView == null || direction === sortDirection) {
          return;
        }
        this.setState({
          companionView: {
            sortDirection: direction,
            sortBy,
          },
        });
      },
      onSortSelection: (sortOption) => {
        if (companionView == null || sortOption.dataIndex === sortBy) {
          return;
        }
        this.setState({
          companionView: {
            sortBy: sortOption.dataIndex,
            sortDirection,
          },
        });
      },
      onToggleCollapse: (isCollapsed) => {
        this.setState({
          companionCollapsed: isCollapsed,
        });
      },
      noImageUrl: noImage,
    };

    return <CompanionListView {...companionProps} />;
  };

  onConfigUpdateLocal = (config: any) => {
    if (config.isDefault) {
      this.setState({
        companionView: {
          sortDirection: 'desc',
          sortBy: this.props.defaultCompanionSortField,
        },
        companionCollapsed: false,
      });
    }
    this.props.onConfigUpdate(config);
  };

  render() {
    if (!this.props.configLoaded) {
      return <Overlay visible={true} type={'loading'} qaKey={'overtime-loading'} />;
    }

    const {
      title = 'Style Color Over Time',
      viewDefn,
      unmodifiedViewDefn,
      subheaderState,
      data,
      dataLoaded,
      aggBys,
      customHeader,
      configureSelections,
      defaultConfigureSelections,
      defaultCompanionSortField,
    } = this.props;
    const { companionView, companionCollapsed } = this.state;

    const sortDirection = companionView?.sortDirection;
    const sortBy = companionView?.sortBy || defaultCompanionSortField;

    const viewConfigurator: ViewConfiguratorModalProps = {
      viewConfig: (viewDefn as unknown) as TenantConfigViewData,
      unmodifiedViewDefn: (unmodifiedViewDefn as unknown) as TenantConfigViewData,
      companionData: {
        companionSortDirection: sortDirection,
        companionCollapsed: companionCollapsed,
        companionSortField: sortBy,
      } as CompanionFavoriteData,
      defaultCompanionData: {
        companionSortDirection: 'desc',
        companionCollapsed: false,
        companionSortField: defaultCompanionSortField,
      },
      configureSelections,
      defaultConfigureSelections,
      updateConfig: this.onConfigUpdateLocal,
    };

    const filteredViewDefnCols = viewDefn.columns?.filter((col) => isNil(col.visible) || col.visible);
    const finalViewDefn = { ...viewDefn, columns: filteredViewDefnCols };

    return (
      <div className={listPairStyle}>
        <Subheader
          viewConfigurator={viewConfigurator}
          title={title}
          showSearch={true}
          showFlowStatus={false}
          errorCondition={ASSORTMENT_BUILD_FILTER_ALL_WARNING}
        />
        <div className={'data-container'}>
          {/* Pass in the correct companion sort values that are potentially in sync with a favorites selection */}
          {this.renderCompanionView(sortDirection, sortBy)}
          <div className="over-time-container">
            <OvertimeView
              loaded={dataLoaded}
              viewConfig={finalViewDefn}
              rowHeight={get(finalViewDefn, 'main.rowHeight', 30)}
              aggBys={aggBys}
              treeData={data || []}
              search={subheaderState.search}
              companionOpen={!companionCollapsed}
              hasCompanion={true}
              exportOptions={{
                fileName: title,
                customHeader: customHeader,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, dispatchToProps)(StyleByLevelOvertime);
