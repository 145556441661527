import { FilterGroup, FilterValue } from 'src/types/Scope';
import { FilterSelection, SelectionOverride } from 'src/common-ui/components/Filters/Filters';
import { pick, flatMap, findLast, isNil } from 'lodash';
import { mergeWithId } from 'src/utils/Primitive/Iterable';
import { FilterCondition } from 'src/dao/filterClient';

export function isFilterValueSelected(value: FilterValue, overrides: SelectionOverride[]) {
  const override = findLast(overrides, (over) => over.id === value.id && over.filterDefnId === value.filterDefnId);
  return override ? override.value : value.selected;
}

export function groupSelectedFilters(group: FilterGroup, overrides: SelectionOverride[]) {
  return group.filters.reduce((acc: FilterSelection[], filter: { filterValues: FilterValue[] }) => {
    const filterValues = filter.filterValues || [];
    const selectedFiltersIds = filterValues
      .filter((value) => isFilterValueSelected(value, overrides))
      .map(({ id, filterDefnId }) => ({ id, filterDefnId }));
    return acc.concat(selectedFiltersIds);
  }, []);
}

export function computeFilterSelection(groups: FilterGroup[], overrides: SelectionOverride[]): FilterSelection[] {
  return flatMap(groups, (group) => {
    return groupSelectedFilters(group, overrides);
  });
}

export function calcInitalOverride(groups: FilterGroup[]): SelectionOverride[] {
  return flatMap(groups, (group) => {
    return flatMap(group.filters, (filter) => {
      return (filter.filterValues || [])
        .filter((value) => value.selected)
        .map((value) => {
          return {
            id: value.id,
            filterDefnId: value.filterDefnId,
            value: value.selected,
          };
        });
    });
  });
}

export function mergeSelectionOverrides(...overrides: SelectionOverride[][]) {
  // @ts-ignore
  return overrides.reduce((acc: SelectionOverride[], next: SelectionOverride[]) => {
    return mergeWithId(acc, next, (sel) => JSON.stringify(pick(sel, 'id', 'filterDefnId')));
  });
}

export function getServerFilterSelections(groups: FilterGroup[]) {
  return computeFilterSelection(groups, []);
}

function defnIdExistsInItems(defnId: string, items: FilterCondition[]) {
  return items.some((item) => item.filterId === defnId);
}

function valueExistsInItems(defnId: string, value: string, items: FilterCondition[]) {
  // find item with value's defnId in items and check against that item's set
  const matchedItem = items.find((item) => item.filterId === defnId);

  if (isNil(matchedItem)) {
    return false;
  }

  return matchedItem.filterSet.some((setItem) => setItem === value);
}

export function resetSelectionOverrides(
  selectedOverrides: SelectionOverride[],
  expectedOverrides: FilterCondition[]
): SelectionOverride[] {
  const nextOverrides = selectedOverrides.filter((selection) => {
    // want to leave out this selection current override defnId is not present in expected
    if (!defnIdExistsInItems(selection.filterDefnId, expectedOverrides)) {
      return false;
    }
    return valueExistsInItems(selection.filterDefnId, selection.id, expectedOverrides);
  });

  return nextOverrides;
}
