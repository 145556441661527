import { Field } from '../../pivot/Field';
import { SettingsByKey } from '../../services/Settings';
import { GridApi } from '@ag-grid-community/core';

export function renderFieldDisplay(field: Field, settings: SettingsByKey) {
  const isMeasure = field.group.isMeasureGroup();
  const isRevision = field.group.isRevisionGroup();
  if (isMeasure || isRevision) {
    const fieldId = field.member.id;
    let lookupKey = `metric.${fieldId}.display`;

    if (isRevision) {
      lookupKey = `revision.${fieldId}.display`;
    }
    const settingsEntry = settings[lookupKey];
    if (settingsEntry && settingsEntry.value) {
      return settingsEntry.value;
    }
  }
  return field.member.name;
}

export function between(val: number, lo: number, hi: number) {
  return lo <= val && val <= hi;
}

export const getRowBoundsFromGridApi = (gridApi: GridApi): [number, number] => {
  const currentModel = gridApi.getModel();
  // using private interfaces here, because the public getLastPageRow() returns the full row count
  // and there isn't a public way to get the ViewportRowModel virtual rows
  // @ts-ignore
  const firstRowIndex = currentModel.firstRow;
  // @ts-ignore
  const lastRowIndex = currentModel.lastRow;
  return [firstRowIndex, lastRowIndex];
};

export const getCurrentVirtualRowIndicies = (gridApi: GridApi): [number, number] => {
  // get the numeric index of the current visible columns
  const allCols = gridApi.getAllGridColumns();

  // getAllDisplayedVirtualColumns doesn't return in render order like getAllGridColumns does
  // so we sort it agaisnt allCols, then get the last rendered col
  const visibleCols = gridApi.getAllDisplayedVirtualColumns().sort((leftCol, rightCol) => {
    return allCols.findIndex((c) => c.getId() === leftCol.getId()) -
      allCols.findIndex((c2) => c2.getId() === rightCol.getId());
  });
  if (visibleCols.length > 2 && allCols.length > 2) {
    const firstVisibleColId = visibleCols[0].getId();
    const lastVisibleColId = visibleCols[visibleCols.length - 1].getId();
    const firstVisisbleColIndex = allCols.findIndex((col) => col.getId() === firstVisibleColId);
    const lastVisisbleColIndex = allCols.findIndex((col) => col.getId() === lastVisibleColId);
    return [firstVisisbleColIndex, lastVisisbleColIndex];
  }
  return [-1, -1];
};
