import React, { Component } from 'react';
import { isEmpty, isEqual } from 'lodash';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
// eslint-disable-next-line prettier/prettier
import type { ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import styleEditStyles from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.styles';
import { generateStylePreview } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import Subheader from 'src/components/Subheader/Subheader.container';
import { formatSummaries } from 'src/utils/Pivot/RollUp';
import CompanionList from 'src/components/CompanionList/CompanionList';
import type { StyleEditProps, StyleEditState } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import StylePreview from 'src/components/StylePreview/StylePreview';
import { FabType } from 'src/components/higherOrder/withFab';

export default class StyleEdit extends Component<StyleEditProps, StyleEditState> {
  constructor(props: StyleEditProps) {
    super(props);

    this.state = {
      selectedCompanionItem: undefined,
      scrollTo: this.props.redirectSelection && {
        eventId: Date.now(),
        where: {
          key: 'id',
          value: this.props.redirectSelection,
        },
      },
    };
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: StyleEditProps) {
    if (!prevProps.redirectSelection && this.props.redirectSelection) {
      this.setState({
        scrollTo: {
          eventId: Date.now(),
          where: {
            key: 'id',
            value: this.props.redirectSelection.id,
          },
        },
      });
    }
  }

  onFabClick = () => {
    switch (this.props.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  handleStyleSelection = (style: ListViewable) => {
    if (isEqual(style, this.state.selectedCompanionItem)) {
      this.setState(
        {
          selectedCompanionItem: undefined,
        },
        () => {
          this.setState({
            selectedCompanionItem: style,
          });
        }
      );
    } else {
      this.setState({
        selectedCompanionItem: style,
        scrollTo: {
          eventId: Date.now(),
          where: {
            key: 'id',
            value: style.id,
          },
        },
      });
    }
  };

  render() {
    // config is required to render the rest of the component chain
    // so render only overlay until config is ready
    if (isEmpty(this.props.config) && this.props.isLoading) {
      return <Overlay type="loading" visible={true} qaKey="StyleEditOverlay" />;
    }

    const {
      title,
      isLoading,
      isPrintMode,
      config,
      adornments,
      summary,
      companionFilters,
      previewFilters,
      flowStatusOptions,
      searchKeys,
      styles,
      redirectSelection,
      onSearchChange,
      onFilterChange,
      onShowView,
      onUpdate,
      onError,
    } = this.props;
    const { selectedCompanionItem, scrollTo } = this.state;

    const stylePreviewData = generateStylePreview(selectedCompanionItem);
    const subheaderProps = {
      title,
      sortByDefn: 'AssortmentBuildStyleEditSortBy',
      summary: formatSummaries(summary),
      showAlternateFlowStatus: true,
      showAlternateSearch: true,
      sortByRequired: true
    };

    return (
      <div className={styleEditStyles.buildStyleEditContainer(isPrintMode)}>
        <Overlay type="loading" visible={isLoading} />
        <Subheader {...subheaderProps} />
        <div className="data-container">
          <CompanionList
            config={config.companionView}
            defaultStyleSelected={redirectSelection}
            styles={styles}
            renderSearchComponent={true}
            renderFilterComponent={true}
            search={companionFilters.search}
            flowStatus={companionFilters.flowStatus}
            flowStatusOptions={flowStatusOptions}
            onSearchChange={onSearchChange}
            onFilterChange={onFilterChange}
            onSelectStyle={this.handleStyleSelection}
            scrollTo={scrollTo}
          />
          <div style={{ height: '100%', overflowX: 'auto', flexGrow: 1 }} id={"StyleEditStylePreviewScroller"} data-qa="StyleEditStylePreviewScroller">
            <StylePreview
              adornments={adornments}
              filters={previewFilters}
              searchKeys={searchKeys}
              previewData={stylePreviewData}
              renderSections={true}
              sectionsViewDefns={config.sectionsViewDefns}
              onRefreshView={onShowView}
              onStyleItemEdit={onUpdate}
              onError={onError}
            />
          </div>
        </div>
      </div>
    );
  }
}
