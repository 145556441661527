import { style } from 'typestyle';

const trendDetailsContainer = style({
  $debugName: 'trend-details-container',
  height: 'calc(100% - 25px)',
  $nest: {
    '& .ag-cell': {
      height: '100%',
    },
    '& .ag-row-group':{
      fontWeight: 'bold'
    }
  },
});

const variance = style({
  display: 'flex',
  justifyContent: 'space-between',
  height: 'inherit',
  width: 'inherit',
  $nest: {
    '& .pills': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginRight: '1rem',
    },
    '& .pill-group': {
      display: 'flex',
      height: 'calc(80px / 3)', // FIXME: need to retrieve rowHeight from config
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .pillpercent': {
      height: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 50,
      borderRadius: 8,
      textAlign: 'center',
      color: 'white',
      fontSize: 10,
      lineHeight: '100%',
      flexDirection: 'row',
      gap: 5,
      $nest: {
        '& .fas': {
          fontSize: 10,
        },
      },
    },
    '& .bullet-chart-container': {
      display: 'flex',
      alignItems: 'center',
    },
    // '& .tinynumber': {
    //   // fontSize: 10,
    //   // minWidth: px(75),
    // },
  },
});

export default {
  trendDetailsContainer,
  variance,
};
