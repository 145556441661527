import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, CardActions } from '@material-ui/core';
import { classes } from 'typestyle';

import * as styles from './TemplateModalWithButton.styles';
import { MUIStyles } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ReceiptsAdjCalculator.styles';
import AcceptButton from '../AcceptButton/AcceptButton';
import RejectButton from '../RejectButton/RejectButton';
import { toast } from 'react-toastify';
import ServiceContainer from 'src/ServiceContainer';
import { errorToLoggingPayload } from 'src/services/loggingService';

interface TemplateModalTitleProps {
  title: string;
  onCancel: () => void;
}

export const TemplateModalTitle = ({ title, onCancel }: TemplateModalTitleProps) => {
  return (
    <div className={styles.titleContainer}>
      <div className={classes(styles.leftTitleContainer, styles.modalTitleText)}>{title}</div>
      <div className={classes(styles.rightTitleContainer, styles.modalTitleText)}>
        <i className={classes('far fa-times', styles.modalTitleIcon)} onClick={onCancel} />
      </div>
    </div>
  );
};

export interface TemplateModalWithButtonProps {
  buttonComponent: JSX.Element;
  customSubmitComponent?: JSX.Element;
  title: string;
  showSubmit?: boolean;
  openOverride?: boolean; // Override open to a value by changing from undefined -> value
  onOpen?: () => void;
  disabled?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  styleOverrides?: React.CSSProperties;
}

export interface TemplateModalWithButtonState {
  open: boolean;
}

class TemplateModalWithButton extends React.Component<TemplateModalWithButtonProps, TemplateModalWithButtonState> {
  constructor(props: TemplateModalWithButtonProps) {
    super(props);
    this.state = {
      open: props.openOverride || false,
    };
  }

  componentDidUpdate(prevProps: TemplateModalWithButtonProps) {
    if (prevProps.openOverride !== this.props.openOverride && this.props.openOverride === false) {
      this.setState({
        open: false,
      });
    }
  }

  open = () => {
    if (this.props.disabled) return;
    if (this.props.onOpen) {
      this.props.onOpen();
    }
    this.setState({
      open: true,
    });
  };

  submit = () => {
    try {
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
      this.setState({ open: false });
    } catch (error) {
      const message = 'Updating style with similar item attributes has failed';
      toast.error(message);
      ServiceContainer.loggingService.error(message, errorToLoggingPayload(error));
    }
  };

  cancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.setState({ open: false });
  };
  render() {
    const { title, customSubmitComponent } = this.props;
    return (
      <div>
        <span className={styles.wrenchStyleButton} onClick={this.open}>
          {this.props.buttonComponent}
        </span>
        <Dialog
          open={this.state.open}
          maxWidth={false}
          PaperProps={{ style: { overflow: 'visible', ...this.props.styleOverrides } }}
        >
          <DialogTitle classes={{ root: styles.modalTitle }} disableTypography={true}>
            <TemplateModalTitle title={title} onCancel={this.cancel} />
          </DialogTitle>
          <DialogContent classes={{ root: styles.modalContent }}>{this.props.children}</DialogContent>
          {this.props.showSubmit && (
            <CardActions classes={MUIStyles.cardActions}>
              {customSubmitComponent ? (
                <div onClick={() => this.submit()}>{customSubmitComponent}</div>
              ) : (
                <React.Fragment>
                  <AcceptButton onClick={() => this.submit()} />
                  <RejectButton onClick={() => this.cancel()} />
                </React.Fragment>
              )}
            </CardActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default TemplateModalWithButton;
