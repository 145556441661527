import React from 'react';
import { isEmpty, omit } from 'lodash';
import { style, classes } from 'typestyle';
import Skeleton from '@material-ui/lab/Skeleton';

export interface CenteredImageProps {
  src?: string;
  width: number;
  height: number | string;
  extraContainerClass?: string;
  extraImgProps?: Record<string, any>;
}

const styles = {
  image: style({
    $debugName: 'CenteredImage',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'inline-block',
    verticalAlign: 'middle',
  }),
  makeImageContainer: (width: number, height: number | string) =>
    style({
      width: width,
      height: height,
      lineHeight: typeof height === 'number' ? `${height}px` : height,
      textAlign: 'center',
    }),
};

const CenteredImage = (props: CenteredImageProps) => {
  const { src, width, height, extraImgProps, extraContainerClass } = props;
  // do not want to pass isBlankCard flag onto <img> element below
  const filteredExtraImgProps = omit(extraImgProps, 'isBlankCard');

  if (extraImgProps?.isBlankCard) {
    return null;
  }

  return (
    <div className={classes(styles.makeImageContainer(width, height), extraContainerClass)}>
      {isEmpty(src) ? (
        <Skeleton variant="rect" className={styles.image} animation={'wave'} width={width} height={height} />
      ) : (
        <img src={src} alt="" className={styles.image} {...filteredExtraImgProps} />
      )}
    </div>
  );
};

export default CenteredImage;
