import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { FitViewGroupPayload, FitViewItemPayload } from 'src/common-ui/components/FitView/FitViewInterfaces';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { BasicItem, BasicPivotItem, WorklistInfo } from 'src/worker/pivotWorker.types';
import { GroupBySlice, SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import Renderer from 'src/utils/Domain/Renderer';
import { getSubheader } from 'src/pages/Hindsighting/Performance/ParetoAnalysis/ParetoAnalysis.selectors';
import { Summary, toSummaries } from 'src/utils/Pivot/RollUp';
import { filterData, mergeWorklistForSort } from 'src/utils/Pivot/Filter';
import * as fp from 'lodash/fp';
import { getGroupBySelectedOptionProperty, maybeSortPivotItems } from 'src/utils/Pivot/Sort';
import { flattenAndLabelGroup } from 'src/components/StandardCardView/SortAndGroup';
import { reorderGroups } from 'src/utils/Domain/Constants';
import coalesce from 'src/utils/Functions/Coalesce';
import { getDataFromCache } from 'src/services/pivotServiceCache';
import { possiblyReorderGroups } from 'src/components/StandardCardView/CardViewDataProcessor';
import { isNil } from 'lodash';
import { evaluateFormula } from 'src/utils/LibraryUtils/MathUtils';

export interface ResponseObject {
  data: BasicPivotItem[];
  viewDefn: TenantConfigViewData;
  searchProps: string[];
  subheader: SubheaderSlice;
  worklist?: WorklistInfo[];
}

export interface GroupItem extends Pick<BasicItem, 'id' | 'description'> { }
export interface Group {
  header: string;
  items: GroupItem[];
}

const getTreeData = (state: AppState) => {
  return getDataFromCache(state.pages.hindsighting.styleColorReview.collectionView)?.tree || [];
};

const getViewSlice = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.collectionView;
};

const getSearchIndexes = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.collectionView.viewDefn.searchIndexes;
};

const getWorklist = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.sharedData.worklist;
};

export function buildGroupsFromFlatData(flatData: BasicPivotItem[], groupBy: GroupBySlice) {
  const groupMap: { ' '?: Group } = {};
  const groupingSelection = coalesce(groupBy.selection, groupBy.defaultSelection);
  let groupProp = '';
  if (!isNil(groupingSelection)) {
    groupProp = groupBy.options[groupingSelection].dataIndex;
    flatData.forEach((style) => {
      // due to an edge case with shopzilla rating and the sorting thereof,
      // these groups identifiers need to map 1:1 to the special sorting in constants.reorderGroups
      // see INT-625
      const cleanedStyleGroupProp =
        style[groupProp] &&
        (typeof style[groupProp] === 'string' ? style[groupProp].replace(/[\.\[\]]/, (match: string) => match === '.' ? '\u2024' : '') : style[groupProp]);
      if (!groupMap[cleanedStyleGroupProp]) {
        groupMap[cleanedStyleGroupProp] = {
          header: cleanedStyleGroupProp,
          items: [],
        };
      }
      const group = groupMap[cleanedStyleGroupProp];
      group.items.push(style);
    });
  } else {
    const gMap = groupMap[' '];
    if (!gMap) {
      groupMap[' '] = {
        header: '',
        items: flatData,
      };
    }
  }
  const { reorderedGroups } = possiblyReorderGroups(groupMap, groupProp, []);
  return reorderedGroups;
}

const getGroupProp = (groupBy: GroupBySlice) => {
  const groupingSelection = coalesce(groupBy.selection, groupBy.defaultSelection);
  if (groupingSelection) {
    return groupBy.options[groupingSelection].dataIndex;
  }
  return '';
};
const getActualValue = (group: Group, dataIndex: string) => {
  if (typeof group[dataIndex] !== 'undefined') {
    return group[dataIndex];
  }
  return null;
};
const getDisplayValue = (itemValue: number | string | null, renderer?: string) => {
  if (renderer && Renderer[renderer]) {
    return Renderer[renderer](itemValue);
  }
  return '';
};
const getFvSubHeader = (group: Group, configItem: TenantConfigViewItem) => {
  const name = configItem.text || '';
  const itemValue = getActualValue(group, configItem.dataIndex);
  const value = getDisplayValue(itemValue, configItem.renderer);
  return { name, value };
};
const getFvItem = (flatItem: FitViewItemPayload) => ({
  id: flatItem.id,
  description: flatItem.description,
  image: flatItem['attribute:img:id'],
});

const getFvGroup = (group: Group, viewItems: TenantConfigViewItem[]): FitViewGroupPayload => {
  const groupItems = group.items;
  const header = `${group.header || ''} (${groupItems.length})`;
  const subheader = viewItems.map((configItem: TenantConfigViewItem) => getFvSubHeader(group, configItem));
  // @ts-ignore
  const items = groupItems.map(getFvItem);
  return { header, subheader, items };
};

export function buildUIData(responseData: ResponseObject): FitViewGroupPayload[] {
  const { data, viewDefn, searchProps, subheader } = responseData;
  const searchFields = viewDefn.searchIndexes ? viewDefn.searchIndexes : searchProps;
  const { groupBy, sortBy, search, flowStatus, pareDown } = subheader;
  const viewItems = viewDefn.view.filter((item) => item.atGroupLevel !== false);

  const withFormulas = (group: Group) => {
    const formulaValues = {};
    viewItems.forEach((viewItem) => {
      formulaValues[viewItem.dataIndex] = evaluateFormula(viewItem.formula || '0', group.items, viewItem.dataIndex);
    });
    return {
      ...group,
      ...formulaValues,
    };
  };

  const theFlow = fp.flow(
    (pivotItems: BasicPivotItem[]) => {
      const groupDataIndex = getGroupBySelectedOptionProperty(groupBy, 'dataIndex');
      const groupDimension = getGroupBySelectedOptionProperty(groupBy, 'dimension');
      return flattenAndLabelGroup({ items: pivotItems, groupDataIndex, groupDimension });
    },
    (pivotItems: BasicPivotItem[]) => {
      const mergedData = responseData.worklist ? mergeWorklistForSort(pivotItems, responseData.worklist) : pivotItems;
      return filterData(mergedData, search, searchFields, flowStatus, pareDown && pareDown.selections);
    },
    (pivotItems: BasicPivotItem[]) => {
      return maybeSortPivotItems(sortBy, pivotItems);
    },
    (pivotItems: BasicPivotItem[]) => {
      return buildGroupsFromFlatData(pivotItems, groupBy);
    },
    (groups: Group[]) => {
      // Remove Empty Groups
      return groups.filter((group) => group.items.length !== 0);
    },
    (groups: Group[]) => {
      // Enrich with formula values
      return groups.map(withFormulas);
    },
    (groups: Group[]) => {
      // 'Performance' groups have to order in a special way, do that using pre-defined reorderGroups
      const groupProp = getGroupProp(groupBy);
      const reorderDetails = reorderGroups.find((details) => details.id === groupProp);
      if (reorderDetails) {
        return groups;
      }
      // Sort the groups
      return maybeSortPivotItems(sortBy, groups);
    },
    (groups: Group[]) => {
      // Convert to object with properties expected for fit view
      return groups.map((group) => getFvGroup(group, viewItems));
    }
  );
  return theFlow(data);
}

export const getProcessedStyleColorData = createSelector(
  getTreeData,
  getViewSlice,
  getSubheader,
  getWorklist,
  (treeData, viewSlice, subheader, worklist): FitViewGroupPayload[] => {
    if (treeData == null || treeData.length == 0 || !viewSlice || !subheader) {
      return [];
    }
    try {
      return buildUIData({
        data: treeData,
        viewDefn: viewSlice.viewDefn,
        searchProps: viewSlice.searchProps,
        subheader,
        worklist,
      });
    } catch (e) {
      return [];
    }
  }
);

export interface GetSummaryOutput extends Summary { }

export const getStyleColorSummary = createSelector(
  getTreeData,
  getViewSlice,
  getSubheader,
  getSearchIndexes,
  (treeData, viewSlice, subheader, searchIndexes) => {
    const views = viewSlice.viewDefn.view;
    const groupDataIndex = getGroupBySelectedOptionProperty(subheader.groupBy, 'dataIndex');
    const groupDimension = getGroupBySelectedOptionProperty(subheader.groupBy, 'dimension');
    const flat = flattenAndLabelGroup({ items: treeData, groupDataIndex, groupDimension });
    const searchFields = searchIndexes ? searchIndexes : viewSlice.searchProps;

    if (fp.isNil(flat) || flat.length === 0 || !Array.isArray(views) || views.length === 0) {
      return [] as Summary[];
    }
    const filteredFlat = filterData(
      flat,
      subheader.search,
      searchFields,
      subheader.flowStatus,
      subheader.pareDown && subheader.pareDown.selections
    );

    return toSummaries(filteredFlat, views, viewSlice.identityProps.idProp);
  }
);

export const getFilteredFlatStyleColorData = createSelector(
  getTreeData,
  getViewSlice,
  getSubheader,
  getSearchIndexes,
  (treeData, viewSlice, subheader, searchIndexes) => {
    const groupDataIndex = getGroupBySelectedOptionProperty(subheader.groupBy, 'dataIndex');
    const groupDimension = getGroupBySelectedOptionProperty(subheader.groupBy, 'dimension');
    const flat = flattenAndLabelGroup({ items: treeData, groupDataIndex, groupDimension });
    const searchFields = searchIndexes ? searchIndexes : viewSlice.searchProps;
    return filterData(
      flat,
      subheader.search,
      searchFields,
      subheader.flowStatus,
      subheader.pareDown && subheader.pareDown.selections
    );
  }
);
