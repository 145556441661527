import React, { Component } from 'react';
import { debounce, isNil, isEmpty, isEqual, noop } from 'lodash';
import { classes } from 'typestyle';
import { CompanionListView } from 'src/common-ui/index';
import { ListViewable, SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { resolvePath } from 'src/cdn';
import { CompanionListProps, CompanionListState } from 'src/components/CompanionList/CompanionList.types';
import { companionStyles } from '../../common-ui/components/CompanionListView/CompanionListView.styles';
import { companionDataParse } from 'src/components/ListGridPair/ListGridPair.utils';
import { parseCompanionListViewConfig } from 'src/utils/Component/ListView';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import SubheaderCheckboxes from 'src/components/Subheader/SubheaderCheckboxes';
import { FlowStatus } from 'src/services/configuration/codecs/bindings.types';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';
const noImage = resolvePath(noImagePath);

type CompanionListSearchProps = {
  search: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CompanionListSearch = ({ search, onChange }: CompanionListSearchProps) => {
  return (
    <input
      type="text"
      className="form-control"
      placeholder="Search list..."
      aria-label="Search list"
      onChange={onChange}
      defaultValue={search}
      data-qa="CompanionListSearchInput"
    />
  );
};

type CompanionListFilterProps = {
  flowStatus: number[];
  flowStatusOptions: FlowStatus;
  onFilterChange: (values: number[]) => void;
};

const CompanionFilter = ({ flowStatus, flowStatusOptions, onFilterChange }: CompanionListFilterProps) => {
  return (
    <SubheaderCheckboxes
      flowStatus={flowStatus}
      flowStatusOptions={flowStatusOptions}
      handleValuesUpdate={onFilterChange}
      qaPrefix="companion-list-view"
      show={true}
    />
  );
};

export default class CompanionList extends Component<CompanionListProps, CompanionListState> {
  debounceUpdateSearch: (value: string) => void;

  constructor(props: CompanionListProps) {
    super(props);

    this.debounceUpdateSearch = props.onSearchChange ? debounce(props.onSearchChange, 200) : noop;

    this.state = {
      collapsed: false,
      sortDirection: 'desc',
      scrollTo: this.props.scrollTo,
    };
  }

  getIdentityKey = () => {
    const { config } = this.props;
    return config.itemMappings.main.title;
  };

  findStyleIndex = (data: ListViewable[], id: string) => {
    return data.findIndex((companionStyle) => companionStyle.id === id);
  };

  shouldComponentUpdate(nextProps: CompanionListProps, nextState: CompanionListState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
  }

  componentDidUpdate() {
    if (!isEqual(this.props.scrollTo, this.state.scrollTo)) {
      const identityKey = this.getIdentityKey();
      if (!this.state.scrollTo || (this.props.scrollTo && this.props.scrollTo.eventId > this.state.scrollTo.eventId)) {
        this.setState({
          scrollTo: this.props.scrollTo,
          selectedStyle:
            this.props.scrollTo && this.props.styles.find((x) => x[identityKey] == this.props.scrollTo?.where.value),
        });
      }
    }
  }

  getSelectedIndex = (companionData: ListViewable[]) => {
    const identityKey = this.getIdentityKey();
    const { defaultStyleSelected } = this.props;
    const { selectedStyle } = this.state;

    // check for default selection from props if no internal selection detected
    if (isNil(selectedStyle)) {
      if (!isNil(defaultStyleSelected)) {
        return this.findStyleIndex(companionData, defaultStyleSelected[identityKey]);
      } else {
        return 0;
      }
    } else if (!isNil(selectedStyle)) {
      return this.findStyleIndex(companionData, selectedStyle[identityKey]);
    } else {
      return -1;
    }
  };

  getDefaultSortSelection = () => {
    const { config } = this.props;
    const defaultSortField = config.sortBy.defaults.dataIndex;

    return config.sortBy.options.findIndex((option) => option.dataIndex === defaultSortField);
  };

  getLevelSelection = () => {
    const { config, levelField } = this.props;
    if (isNil(config.levelBy) || isNil(levelField)) {
      return -1;
    }
    return config.levelBy.view.findIndex((option) => option.dataIndex === levelField);
  };

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.debounceUpdateSearch(event.currentTarget.value);
  };

  handleFilterChange = (values: number[]) => {
    if (this.props.onFilterChange) {
      this.props.onFilterChange(values);
    }
  };

  handleSortDirectionChange = (sortDirection: SortByDirection) => {
    const identityKey = this.getIdentityKey();
    const { selectedStyle } = this.state;
    if (this.props.onSortDirectionChange) {
      this.props.onSortDirectionChange(sortDirection);
    }

    this.setState({
      sortDirection,
      scrollTo: {
        eventId: Date.now(),
        where: {
          key: identityKey,
          value: !isNil(selectedStyle) ? selectedStyle[identityKey] : '',
        },
      },
    });
  };

  handleSortSelection = (selection: SortOption) => {
    if (this.props.onSortChange) {
      this.props.onSortChange(selection.dataIndex);
    }
    this.setState({ sortField: selection.dataIndex });
  };

  handleToggleCollapse = (isCollapsed: boolean) => this.setState({ collapsed: isCollapsed });

  handleLevelSelection = (levelOption: SortOption) => {
    const { onLevelChange } = this.props;
    if (onLevelChange) {
      onLevelChange(levelOption.dataIndex);
    }
  };

  handleListItemClicked = (
    identityValue: string,
    companionData: ListViewable[],
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { styles, onSelectStyle } = this.props;
    const selectedItem = companionData.find((item) => item.id === identityValue);
    const identityKey = this.getIdentityKey();

    if (this.props.onlyUserInitiatedClicks && selectedItem) {
      this.props.onSelectStyle(selectedItem, event);
      return;
    }
    let selectedStyle;
    if (selectedItem) {
      selectedStyle = styles.find((style) => style[identityKey] === selectedItem.id);
      const companionItem = companionData.find((x) => x.id == identityValue);
      if (companionItem) {
        onSelectStyle(companionItem, event);
      }
    }

    this.setState({
      selectedStyle,
    });
  };

  renderSearchComponent = () => {
    const { renderSearchComponent, search = '' } = this.props;

    return renderSearchComponent ? (
      <CompanionListSearch search={search} onChange={this.handleSearchChange} />
    ) : (
      undefined
    );
  };

  renderFilterComponent = () => {
    const { renderFilterComponent, flowStatus = [], flowStatusOptions = {} as FlowStatus } = this.props;

    return renderFilterComponent ? (
      <CompanionFilter
        flowStatus={flowStatus}
        flowStatusOptions={flowStatusOptions}
        onFilterChange={this.handleFilterChange}
      />
    ) : (
      undefined
    );
  };

  render() {
    const { config, styles, levelField, selectedCompanionItem, onSelectStyle } = this.props;
    const { sortDirection, sortField, scrollTo, selectedStyle } = this.state;
    const dataLookup = parseCompanionListViewConfig((config.itemMappings as unknown) as TenantConfigViewData);
    const defaultSortSelection = this.getDefaultSortSelection();
    const maybeDefaultSortIndex =
      config.sortBy.options[defaultSortSelection] && config.sortBy.options[defaultSortSelection].dataIndex;
    const defaultLevelSelection = this.getLevelSelection();
    const data = companionDataParse(styles, dataLookup, sortDirection, sortField || maybeDefaultSortIndex);
    let selectedIndex: number = this.getSelectedIndex(data);
    const identityKey = this.getIdentityKey();

    // not ideal, but need to notify parent of selected style
    if (!isEmpty(data) && !this.props.onlyUserInitiatedClicks) {
      if (!selectedStyle) {
        // If the main view flushes the companion list data and we want to maintain the current selection
        // Check if the passed companion item exists, and if so, set that as selected
        let foundStyle =
          selectedCompanionItem && styles.find((x) => x[identityKey] == selectedCompanionItem[identityKey]);
        if (!foundStyle) {
          // If no selected item in the main view, or it doesn't exist in the data, select the first item
          onSelectStyle(data[selectedIndex || 0]);
          foundStyle = styles.find((x) => x[identityKey] == data[0].title);
        }
        this.setState({
          selectedStyle: selectedIndex === undefined ? styles[selectedIndex] : foundStyle,
        });
      }
      if (selectedStyle && selectedStyle[identityKey] != data[selectedIndex === -1 ? 0 : selectedIndex][identityKey]) {
        onSelectStyle(data[selectedIndex === -1 ? 0 : selectedIndex]);
        selectedIndex = selectedIndex === -1 ? 0 : selectedIndex;
      }
    }

    return (
      <CompanionListView
        label={'Count'}
        className={classes(companionStyles)}
        sortOptions={config.sortBy.options as SortOption[]}
        defaultSelection={defaultSortSelection}
        levelOptions={levelField && config.levelBy ? (config.levelBy.view as SortOption[]) : undefined}
        defaultLevelSelection={defaultLevelSelection !== -1 ? defaultLevelSelection : undefined}
        selectedIndex={selectedIndex || 0}
        data={data}
        noImageUrl={noImage}
        scrollTo={scrollTo}
        dataLookup={dataLookup}
        searchComponent={this.renderSearchComponent()}
        filterComponent={this.renderFilterComponent()}
        initialSortDirection={sortDirection}
        hoverListItemElement={this.props.hoverActionElement}
        // passing in data here since calculating before render and don't have a good place to generate it in state for access later
        onListItemClicked={(identityValue: string, evt) => this.handleListItemClicked(identityValue, data, evt)}
        onChangeDirection={this.handleSortDirectionChange}
        onSortSelection={this.handleSortSelection}
        onLevelSelection={this.handleLevelSelection}
        onToggleCollapse={this.handleToggleCollapse}
      />
    );
  }
}
