import { style } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { createStyles } from '@material-ui/core';
import { StyledTypographyProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';

const viewConfiguratorStyles = style({
  width: '500px',
  height: '500px',
  $nest: {
    '.columns-title-container': {
      height: 40,
      fontWeight: 'bold',
      display: 'grid',
      gridTemplateColumns: 'auto 55px 55px',
      padding: '0 1.3rem',
    },
    '.columns-grid': {
      overflowY: 'auto',
      height: '470px',
    },
    '.checkbox-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '.child-metric-container': {
      display: 'grid',
      gridTemplateColumns: 'auto 45px',
      width: '100%',
    },
    '.footer': {
      width: '75%',
      bottom: 0,
      height: 43,
      position: 'absolute',
    },
    '.reset-button': {
      float: 'right',
      width: 80,
      height: 35,
      color: 'black',
      backgroundColor: '#d6d6d6',
      $nest: {
        '&.primary': {
          color: 'white',
          backgroundColor: TEAL_PRIMARY,
        },
        '&:disabled': {
          color: 'grey',
          backgroundColor: '#d6d6d6',
        },
      },
      borderStyle: 'none',
      cursor: 'pointer',
      boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
      borderRadius: 2,
      letterSpacing: 0.5,
    },
    '.columns-checkbox': {
      width: '15%',
      textAlign: 'center',
      paddingTop: 1,
      marginLeft: 'auto',
    },
    '.item-text.metric-style': {
      paddingTop: 0,
    },
    '.item-text': {
      paddingLeft: 10,
      paddingTop: 13,
      width: '60%',
      textAlign: 'center',
      marginRight: 50,
    },
    '.item-name': {
      marginRight: 30,
      paddingTop: 10,
      width: '66%',
    },
  },
});

export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  background: isDragging ? 'lightgreen' : 'rgba(0,0,0,.05)',
  display: 'flex',
  marginBottom: 10,
  marginLeft: 20,
  marginRight: 20,
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean, length: number) => ({
  // background: isDraggingOver ? 'lightblue' : 'inherit',
  width: '100%',
  height: 50 * length,
});

export const configuratorAccordionOverrides = createStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
});

export const configuratorAccordionSummaryOverrides = createStyles({
  root: {
    flexDirection: 'row-reverse',
    background: 'rgba(0, 0, 0, 0.05)',
    height: 50,
    padding: '0',
    '&$expanded': {
      minHeight: 50,
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'auto 100px',
    width: '100%',
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
});

export const configuratorAccordionDetailsOverrides = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 0 4rem',
    background: 'rgba(0, 0, 0, 0.05)',
  },
});

export const configuratorAccordionTypographyOverrides = createStyles({
  root: {
    marginLeft: ({ hasIcon }: StyledTypographyProps) => (hasIcon ? 0 : '1rem'),
    maxWidth: '80%',
    alignSelf: 'center',
  },
});

export default viewConfiguratorStyles;
