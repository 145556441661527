import React, { useCallback, useState } from 'react';
import { Button, Segment } from 'semantic-ui-react';
import Modal from '@trendmicro/react-modal';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { PlanMetadata } from 'src/state/scope/codecs/PlanMetadata';
import TitledModal from 'src/components/Modal/TitledModal';
import { Overlay } from 'src/common-ui';

interface DeleteIconRenderProps extends ICellRendererParams {
  data: PlanMetadata,
  onClick: (data: PlanMetadata) => void,
  deletePlanLoading: boolean;
}

export default function DeleteIconRenderer(props: DeleteIconRenderProps) {
  const { onClick, data, deletePlanLoading } = props;
  const [isModalVisible, setModalVisible] = useState(false);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);
  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);
  const handleSubmit = useCallback(() => {
    onClick(data);
  }, [onClick, data]);

  const modal = !isModalVisible ? null :
    <TitledModal
      title={'Delete Plan'}
      show={isModalVisible}
      data-qa={'delete-plan-modal'}
      className={'import-version-modal'}
    >
      <Modal.Body>
      {deletePlanLoading && <Overlay type="loading" visible={true} fitParent={true} />}
        <Segment>
          {/* eslint-disable-next-line max-len */}
          This action will delete the plan named &quot;<strong>{data.name}</strong>&quot;.  This <em>cannot</em> be undone.  Do you wish to proceed?
        </Segment>
      </Modal.Body>
      <Modal.Footer>
        <Button content="Cancel" onClick={closeModal} />
        <Button
          content="Delete"
          className="import-version-modal-button"
          data-qa="delete-plan-btn-delete"
          onClick={handleSubmit}
        />
      </Modal.Footer>
    </TitledModal>;

  return (
    <React.Fragment>
      <Button
        icon="trash"
        color="red"
        onClick={openModal}
      />
      {modal}
    </React.Fragment>);
};
