import * as React from 'react';
import Axios from 'src/services/axios';
import serviceContainer from 'src/ServiceContainer';
import { isEmpty, cloneDeep, isArray, mapValues, isNil, isString, map, isEqual } from 'lodash';
import CardActions from '@material-ui/core/CardActions';
import Dialog from '@material-ui/core/Dialog';
import { CancelTokenSource, default as AxiosStatic } from 'axios';
import { styles, MUIStyles } from './LifecycleStoreModal.styles';
import Lifecycle from 'src/pages/AssortmentBuild/LinePlan/Lifecycle/Lifecycle';
import StoreEligibility, { StoreData } from 'src/pages/AssortmentBuild/LinePlan/StoreEligibility/StoreEligibility';
import { arrayStringToArray } from 'src/utils/Primitive/String';
import {
  DependentData,
  MultiRangeEditors,
  StyleEditConfigColumn,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.types';
import AcceptButton from 'src/components/AcceptButton/AcceptButton';
import RejectButton from 'src/components/RejectButton/RejectButton';
import { maybeReturnNestData } from 'src/utils/Http/NestedDatas';
import { MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import { ClientDataApi, ConfigApiV2 } from 'src/services/configuration/codecs/confdefnView';

interface LifecycleConfig {
  multiRangeEditors: MultiRangeEditors;
  planningColumns: StyleEditConfigColumn[];
  title: string;
  weekRangeColumns: StyleEditConfigColumn[];
  [key: string]: unknown;
}

interface StoreConfig {
  data: StyleEditConfigColumn[];
}

export interface LifecycleData {
  [key: string]: number | string | string[] | boolean | null;
}

export interface LifecycleStoreData {
  lifecycleData: LifecycleData;
  storeData: StoreData;
}

export interface LifecycleParametersProps {
  textToDisplay: string;
  dataApiLifecycle: ClientDataApi;
  dataApiStore: ClientDataApi;
  lifecycleConfig: ConfigApiV2;
  storeConfig: ConfigApiV2;
  dependentsApi?: ClientDataApi;
  onSubmit: (data: LifecycleStoreData) => void;
}

export interface LifecycleParametersState {
  activeTabIndex: number;
  lifecycleConfig: LifecycleConfig | null;
  storeConfig: StoreConfig | null;
  dependentsData: DependentData | null;
  open: boolean;
  lifecycleData: LifecycleData | null;
  storeData: StoreData | null;
  loading: boolean;
}

class LifecycleParametersEditor extends React.Component<LifecycleParametersProps, LifecycleParametersState> {
  lifecycleRef: React.RefObject<Lifecycle> = React.createRef();
  storeRef: React.RefObject<StoreEligibility> = React.createRef();
  containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  canceler: CancelTokenSource = AxiosStatic.CancelToken.source();
  updateCanceler: CancelTokenSource = AxiosStatic.CancelToken.source();
  constructor(props: LifecycleParametersProps) {
    super(props);
    this.state = {
      lifecycleConfig: null,
      activeTabIndex: 0,
      storeConfig: null,
      dependentsData: null,
      open: false,
      lifecycleData: null,
      storeData: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.getConfigsAndData();
  }

  componentDidUpdate(prevProps: LifecycleParametersProps) {
    if (
      !isEqual(this.props, prevProps) &&
      this.props.dataApiLifecycle &&
      prevProps.dataApiLifecycle &&
      !this.state.loading
    ) {
      this.getConfigsAndData();
    }
  }
  componentWillUnmount() {
    this.canceler.cancel();
  }

  getConfigsAndData() {
    const { dataApiLifecycle, dataApiStore, lifecycleConfig, storeConfig, dependentsApi } = this.props;
    const lifecycleDefnId = lifecycleConfig.defnId || 'AssortmentStoreEligibilityDefault';
    const storeDefnId = storeConfig.defnId || 'AssortmentLifecycleDefault';

    const lifecycleConfigGet = serviceContainer.tenantConfigClient.getTenantViewDefn<LifecycleConfig>({
      defnId: lifecycleDefnId,
    });
    const storeConfigGet = serviceContainer.tenantConfigClient.getTenantViewDefn<StoreConfig>({
      defnId: storeDefnId,
    });
    const dependentsData = Axios.get(dependentsApi && dependentsApi.url ? dependentsApi.url : '', {
      cancelToken: this.canceler.token,
    });
    const lifecycleData = Axios.get(dataApiLifecycle.url, {
      params: {
        ...dataApiLifecycle.params,
      },
      cancelToken: this.canceler.token,
    });
    const storeData = Axios.get(dataApiStore.url, {
      params: {
        ...dataApiStore.params,
      },
      cancelToken: this.canceler.token,
    });

    Promise.all([lifecycleConfigGet, dependentsData, storeConfigGet, lifecycleData, storeData]).then((responses) => {
      const lifecycleConfig = responses[0];
      const dependentsData: DependentData = responses[1].data.data;
      const storeConfig = responses[2];
      const lifecycleData = responses[3].data.data;
      const storeData = responses[4].data.data;
      const storeDataList = isArray(storeData) ? storeData : Object.values(storeData);
      const mappedStoreData = map(storeDataList, (value) => {
        const layer = value[0];
        return [
          mapValues(layer, (propertyValue) => {
            if (!isString(propertyValue)) {
              return propertyValue;
            }
            return arrayStringToArray(propertyValue, true);
          }),
        ];
      });
      this.setState({
        lifecycleConfig: maybeReturnNestData(lifecycleConfig) as LifecycleConfig,
        dependentsData,
        storeConfig,
        lifecycleData,
        storeData: mappedStoreData,
        loading: false,
      });
    });
  }

  reloadStoreData = async () => {
    const { dataApiStore } = this.props;
    const storeDataResp = await Axios.get(dataApiStore.url, {
      params: {
        ...dataApiStore.params,
      },
      cancelToken: this.canceler.token,
    });
    let storeData = storeDataResp.data.data;
    if (!isArray(storeDataResp.data.data)) {
      storeData = Object.values(storeDataResp.data.data);
    }
    storeData = map(storeData, (value) => {
      const layer = value[0];
      return [
        mapValues(layer, (propertyValue) => {
          if (!isString(propertyValue)) {
            return propertyValue;
          }
          return arrayStringToArray(propertyValue, true);
        }),
      ];
    });
    this.setState({
      storeData,
      loading: false,
    });
  };
  reloadLifecycleData = async () => {
    const { dataApiLifecycle } = this.props;
    const lifecycleDataResp = await Axios.get(dataApiLifecycle.url, {
      params: {
        ...dataApiLifecycle.params,
      },
      cancelToken: this.canceler.token,
    });
    const lifecycleData = lifecycleDataResp.data.data;
    this.setState({
      lifecycleData,
      loading: false,
    });
  };

  uploadLifecycleData = async (lifecycleData: LifecycleData | null) => {
    const { dataApiLifecycle } = this.props;
    const attributes = !isNil(lifecycleData) ? lifecycleData : {};
    this.updateCanceler.cancel();
    this.updateCanceler = AxiosStatic.CancelToken.source();

    await Axios.post(dataApiLifecycle.url, { attributes }, { cancelToken: this.updateCanceler.token });
  };

  onRefetchData = () => {
    this.reloadStoreData();
    this.reloadLifecycleData();
  };

  uploadStoreData = async (storeData: StoreData | null) => {
    const { dataApiStore } = this.props;

    this.updateCanceler.cancel();
    this.updateCanceler = AxiosStatic.CancelToken.source();
    await Axios.post(
      dataApiStore.url,
      {
        attributes: storeData,
      },
      {
        cancelToken: this.updateCanceler.token,
      }
    );
  };

  onClickTab = (index: number) => {
    const { activeTabIndex } = this.state;
    const lifecycleData = this.getLifecycleData();
    const storeData = this.getStoreData();

    if (index !== activeTabIndex) {
      this.setState(
        {
          activeTabIndex: index,
          loading: true,
        },
        async () => {
          if (activeTabIndex === 0) {
            await this.uploadLifecycleData(lifecycleData);
            await this.reloadStoreData();
          } else {
            if (!isEmpty(this.state.storeData)) {
              await this.uploadStoreData(storeData);
            }
            await this.reloadLifecycleData();
          }
        }
      );
    }
  };

  getDataStore = (storeData: any) => {
    const { storeData: origStoreData } = this.state;
    const origStoreDataCopy = cloneDeep(origStoreData);

    Object.keys(storeData).forEach((key) => {
      if (!isNil(origStoreDataCopy) && !isArray(storeData[key])) {
        origStoreDataCopy[key] = [storeData[key]];
      }
    });

    this.setState({
      storeData: origStoreDataCopy,
    });
  };

  getLifecycleData = (): LifecycleData | null => {
    const ref = this.lifecycleRef;
    return !isNil(ref) && !isNil(ref.current) ? ref.current.getLifecycleData() : null;
  };

  getStoreData = (): StoreData | null => {
    const ref = this.storeRef;
    return !isNil(ref) && !isNil(ref.current) ? ref.current.saveAfterEdit() : null;
  };

  submit = async () => {
    let lifecycleData = this.state.lifecycleData;
    let storeData = this.state.storeData;

    if (this.state.activeTabIndex === 0) {
      lifecycleData = this.getLifecycleData();
      await this.uploadLifecycleData(lifecycleData);
    } else {
      storeData = this.getStoreData();
      await this.uploadStoreData(storeData);
    }

    this.setState({
      open: false,
      activeTabIndex: 0,
      lifecycleData,
      storeData,
    });

    lifecycleData = !isNil(lifecycleData) ? lifecycleData : {};
    storeData = !isNil(storeData) ? storeData : [];

    this.props.onSubmit({
      lifecycleData,
      storeData,
    });
  };

  cancel = () => {
    this.setState({ open: false, activeTabIndex: 0 });
  };

  render() {
    const {
      loading,
      activeTabIndex,
      lifecycleConfig,
      dependentsData,
      storeConfig,
      lifecycleData,
      storeData,
    } = this.state;
    let content = <div />;
    if (loading) {
      content = (
        <div style={{ minHeight: '260px' }}>
          <Overlay visible={true} type={'loading'} fitParent={true} data-qa="overlay" />
        </div>
      );
    } else if (lifecycleData && !isEmpty(lifecycleData)) {
      content = activeTabIndex ? (
        isEmpty(storeData) ? (
          <div className={styles.noDataContainer} data-qa="no-data-found">
            No Data Found
          </div>
        ) : (
          <StoreEligibility
            data={storeData}
            config={storeConfig}
            getDataStore={this.getDataStore}
            ref={this.storeRef}
            popoverParent={this.containerRef.current}
          />
        )
      ) : (
        <Lifecycle
          weekRangeData={lifecycleData}
          weekRangeConfig={!isNil(lifecycleConfig) ? lifecycleConfig.weekRangeColumns : null}
          weekRangeFieldEditConfig={!isNil(lifecycleConfig) ? lifecycleConfig.multiRangeEditors : null}
          planningConfig={!isNil(lifecycleConfig) ? lifecycleConfig.planningColumns : null}
          dependentsData={!isNil(dependentsData) ? dependentsData : null}
          ref={this.lifecycleRef}
          popoverParent={this.containerRef.current}
        />
      );
    }
    return (
      <React.Fragment>
        <MuiThemeProvider theme={muiTheme}>
          {/* theme provider re-exported here because for reasons that are unclear, the theme doesn't apply witin this component correctly */}
          <span data-qa="review-assortment-button" onClick={() => this.setState({ open: true })}>
            {this.props.textToDisplay}
          </span>
          <Dialog
            open={this.state.open}
            maxWidth={'xl'}
            // due to the way this component is implemented this is the safest way to make sure
            // `containerRef` is not null before <Lifecycle /> is rendered
            keepMounted={true}
            PaperProps={{ style: { overflow: 'visible' } }}
          >
            <div className={styles.container} ref={this.containerRef} data-qa="lifecycle-store-container">
              <div className={styles.tabContainer}>
                {['Lifecycle and Planning', 'Store Ranging'].map((name, index) => {
                  const tabClass = activeTabIndex === index ? styles.tabButtonActive : styles.tabButton;
                  const dataQa = index === 0 ? 'lifecycle-tab' : 'store-ranging-tab';
                  return (
                    <button
                      className={tabClass}
                      onClick={() => this.onClickTab(index)}
                      key={name}
                      data-qa={`${dataQa}-button`}
                    >
                      {name}
                    </button>
                  );
                })}
              </div>
              <div className={styles.receiptContainer}>
                <div className={'ag-theme-material'}>{content}</div>
                <CardActions classes={MUIStyles.cardActions}>
                  <AcceptButton onClick={this.submit} />
                  <RejectButton onClick={this.cancel} />
                </CardActions>
              </div>
            </div>
          </Dialog>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default LifecycleParametersEditor;
