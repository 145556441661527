import * as React from 'react';
import * as lodash from 'lodash';
import * as typestyle from 'typestyle';
import { SectionProps, FilterValue } from './Filters.interfaces';
import FilterSectionItem from './FilterSectionItem';
import styles from './Filters.styles';
import { flattenDepth, trim, uniq } from 'lodash';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';

export interface State {
  query: string;
  orderBy: SortByDirection;
}

export default class FilterSection extends React.PureComponent<SectionProps, State> {
  state: State = {
    query: '',
    orderBy: 'asc',
  };

  constructor(props: SectionProps) {
    super(props);
  }

  toggleOpen = () => {
    const { onToggleSection, id } = this.props;
    onToggleSection(id);
  };

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ query: event.currentTarget.value });
  };

  toggleFilter = (id: string, filterDefnId: string, value: boolean) => {
    const { onToggleItem } = this.props;
    onToggleItem(id, filterDefnId, value);
  };

  toggleOrderBy = () => {
    const { orderBy } = this.state;
    if (orderBy === 'asc') {
      this.setState({ orderBy: 'desc' });
    } else {
      this.setState({ orderBy: 'asc' });
    }
  };

  hasSelections = (): boolean => {
    const mergedValues = this.props.filterValues.map((x) => {
      // need to check both filterDefnId and id fields in case the id field (which is the name rendered)
      // is duplicated across sections
      const override = this.props.selectionOverrides.find((y) => y.filterDefnId === x.filterDefnId && y.id === x.id);
      return {
        ...x,
        selected: override ? override.value : x.selected,
      };
    });
    return !!(mergedValues || []).find((value) => value.selected === true);
  };

  clearFilterSection = (event: React.MouseEvent<HTMLElement>, isOpen: boolean) => {
    this.props.selectionOverrides
      .filter((value) => value.filterDefnId === this.props.id)
      .forEach((value) => this.props.onToggleItem(value.id, value.filterDefnId, false));
    this.props.onClearSection(isOpen);
    event.stopPropagation();
  };

  getSearchResults = () => {
    const { filterValues, matchFunc } = this.props;
    const { query } = this.state;
    let searchString;
    if (query.includes(';')) {
      searchString = query.split(';').filter(trim);
    }

    let searchResults;
    if (!query) {
      searchResults = filterValues;
    } else {
      if (matchFunc) {
        searchResults = filterValues.filter((value) => matchFunc(value.id.toLowerCase(), query.trim().toLowerCase()));
      } else {
        searchResults = searchString
          ? uniq(
              flattenDepth(
                searchString.map((x) =>
                  filterValues.filter((value) => value.id.toLowerCase().indexOf(x.trim().toLowerCase()) > -1)
                )
              )
            )
          : filterValues.filter((value) => value.id.toLowerCase().indexOf(query.trim().toLowerCase()) > -1);
      }
    }

    return searchResults;
  };

  toggleAllFilters = () => {
    const filterValues = this.getSearchResults();
    const toggleTo = !this.areAllSelected();
    filterValues.forEach((value) => {
      if (!value.disabled) {
        this.props.onToggleItem(value.id, value.filterDefnId, toggleTo);
      }
    });
  };

  areAllSelected = () => {
    const filterValues = this.getSearchResults();
    return filterValues.every((value) => value.disabled || !!value.selected);
  };

  render() {
    const { name, filterValues, open } = this.props;
    const { query, orderBy } = this.state;

    const searchResults = this.getSearchResults();
    const sortedResults = lodash.orderBy(searchResults, (result: FilterValue) => result.id, orderBy);

    let arrowDirection = 'fa-arrow-up';
    if (orderBy === 'desc') {
      arrowDirection = 'fa-arrow-down';
    }

    let toggleArrowDirection = 'fa-chevron-up';
    if (!open) {
      toggleArrowDirection = 'fa-chevron-down';
    }

    const orderByClassName = `sort-dir far ${arrowDirection} d-flex align-self-center`;
    const toggleSectionClassName = `expander far ${toggleArrowDirection}`;

    return (
      <div className="filter-section">
        <div className={typestyle.classes(styles.FlexAroundContainer, styles.clickableArea)} onClick={this.toggleOpen}>
          <p className="section-title">{name}</p>
          <div className={styles.sectionControls}>
            {this.hasSelections() && (
              <i className="far fa-times" onClick={(event) => this.clearFilterSection(event, open)} />
            )}
            <i className={toggleSectionClassName} />
          </div>
        </div>
        {!open && <hr className={styles.separator} />}
        {open && (
          <React.Fragment>
            <div
              className={typestyle.classes(styles.FlexAroundContainer, styles.clickableArea)}
              data-qa="filter-search-container"
            >
              {filterValues && filterValues.length > 0 ? (
                <React.Fragment>
                  <div className="search">
                    <i className="far fa-search" />
                    <input
                      className={styles.searchInput}
                      type="text"
                      value={query}
                      onChange={this.handleChange}
                      placeholder="Search this filter"
                    />
                    <span className="select-all" onClick={this.toggleAllFilters}>
                      {this.areAllSelected() ? 'Remove All' : 'Select All'}
                    </span>
                  </div>
                  <i className={orderByClassName} onClick={this.toggleOrderBy} />
                </React.Fragment>
              ) : (
                <div className="empty">No values to display</div>
              )}
            </div>
            <div className="pt-2" data-qa="filter-items-container">
              {sortedResults &&
                sortedResults.map((filterValue, index) => {
                  const override = this.props.selectionOverrides.find(
                    (o) => o.id === filterValue.id && o.filterDefnId === filterValue.filterDefnId
                  );
                  const selected = override === undefined ? Boolean(filterValue.selected) : override.value;
                  const selectedOverride = {
                    ...filterValue,
                    selected,
                  };
                  return (
                    <FilterSectionItem
                      key={`filter_item_${index}`}
                      {...selectedOverride}
                      onToggleFilter={this.toggleFilter}
                    />
                  );
                })}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
