import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { SelectionOverride, FilterSelection } from 'src/common-ui/components/Filters/Filters';

import serviceContainer from 'src/ServiceContainer';
import { AppDispatch, AppState, AppThunkDispatch } from 'src/store';
import { FilterGroup } from 'src/types/Scope';
import { computeFilterSelection } from 'src/utils/Filter/Filters';
import FilterPanel from './FilterPanel';

import {
  updateSelectionOverrides,
  flushSelectionOverridesStarted,
  receiveFilterState,
  makeUpdateLastSelection,
  receiveFilterStateAfterSelection,
  isLoading,
  rollbackSelectionOverrides,
  receiveFilterStateAfterSubmission,
} from './FilterPanel.slice';
import { getSectionContext } from 'src/utils/Domain/Perspective';
import { toast } from 'react-toastify';
import { getActiveContextString } from 'src/pages/PerspectiveSelection/PerspectiveSelection.selectors';

export interface FilterSelections {
  id: string;
  filterDefnId: string;
}

export interface ReduxSlice {
  isFilterSensitive?: boolean;
  updatedAt?: number;
  state: FilterGroup[];
  isFlushing: boolean;
  isLoading: boolean;
  selectionOverrides: SelectionOverride[];
  lastSelections: FilterSelection[];
  lastPostedSelections: FilterSelection[];
  containsInvalid: boolean;
  selectionsChanged: boolean;
  lastOverridedSection: string;
}

export interface StoreProps extends ReduxSlice {}
export interface Props extends DispatchProps, StoreProps {}

export function submitFilterSelections() {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const storeProps = state.filters;
    const perspective = state.perspective.selected!;
    const appName = perspective.appType;
    const filterSelections = computeFilterSelection(storeProps.state, storeProps.selectionOverrides);
    const section = getActiveContextString(state);
    if (!isEqual(filterSelections, storeProps.lastSelections)) {
      dispatch(flushSelectionOverridesStarted());
      serviceContainer.filterClient
        .setFilterSelections(filterSelections, {
          appName,
          section,
        })
        .then(() => {
          serviceContainer.filterClient
            .getFullFilters({
              appName,
              section,
            })
            .then((refreshResult) => {
              if (refreshResult) {
                const submission = {
                  filters: refreshResult.filters,
                  updatedAt: Date.now(),
                };
                dispatch(receiveFilterStateAfterSubmission(submission));
                dispatch(makeUpdateLastSelection(filterSelections));
              }
            });
        });
    }
  };
}
export function getAvailableFilterSelections(sectionId: string) {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const storeProps = state.filters;
    const perspective = state.perspective.selected!;
    const appName = perspective.appType;
    const filterSelections = computeFilterSelection(storeProps.state, storeProps.selectionOverrides);
    const section = getActiveContextString(state);
    dispatch(isLoading(true));
    serviceContainer.filterClient
      .getAvailableFilterSelections({
        appName,
        section,
        filterSelections,
        attributeId: sectionId,
      })
      .then((refreshResult) => {
        if (refreshResult) {
          dispatch(
            receiveFilterStateAfterSelection({
              filters: refreshResult.filters,
              postedSelections: filterSelections,
            })
          );
        }
      });
  };
}
export function getFilters() {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const perspective = state.perspective.selected!;
    const appName = perspective.appType;
    const section = getSectionContext();

    dispatch(flushSelectionOverridesStarted());
    dispatch(isLoading(true));
    serviceContainer.filterClient
      .getFullFilters({
        appName,
        section,
      })
      .then((result) => {
        if (result) {
          dispatch(receiveFilterState({ filters: result.filters, updatedAt: Date.now() }));
        }
      })
      .catch((err) => {
        dispatch(receiveFilterState({ filters: [], updatedAt: Date.now() }));
        toast.error('An error occured fetching filters');
        serviceContainer.loggingService.error('An error occured fetching filters', err);
      });
  };
}

export function getFilterSelections() {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const perspective = state.perspective.selected!;
    const appName = perspective.appType;
    serviceContainer.filterClient.getOnlyFilterSelections(appName).then((selections) => {
      dispatch(rollbackSelectionOverrides(selections));
    });
  };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    updateSelectionOverrides(overrides: SelectionOverride[]) {
      dispatch(updateSelectionOverrides(overrides));
    },
    submitSelectionOverrides() {
      dispatch(submitFilterSelections());
    },
    getAvailableFilterSelections(sectionId: string) {
      dispatch(getAvailableFilterSelections(sectionId));
    },
  };
};

const mapStateToProps = (state: AppState) => {
  const storeProps = state.filters;
  const filterSelections = computeFilterSelection(storeProps.state, storeProps.selectionOverrides);
  return {
    ...storeProps,
    selectionsChanged: !isEqual(filterSelections, storeProps.lastSelections),
  };
};

export interface DispatchProps extends ReturnType<typeof mapDispatchToProps> {}

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
