import React, { useCallback, useState } from 'react';
import { Button, Segment, Select } from 'semantic-ui-react';
import Modal from '@trendmicro/react-modal';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { PlanMetadata } from 'src/state/scope/codecs/PlanMetadata';
import TitledModal from 'src/components/Modal/TitledModal';
import { AppState, AppThunkDispatch } from 'src/store';
import ServiceContainer from 'src/ServiceContainer';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  useHandleChangeStringVersion,
  usePublishVersionsToDropdowns,
  useSetSelectedStringVersion
} from 'src/utils/Component/hooks/hooks';
import { clonePlans } from 'src/state/workingSets/workingSets.actions';
import { Overlay } from 'src/common-ui';

import 'src/components/Mfp/InitializePlan/_InitializePlan.scss';
import './_ClonePlanRenderer.scss';

const mapStateToProps = (state: AppState) => {
  const { scope } = state;
  return {
    scope,
    publishVersions: state.scopeManagement.publishVersions,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    clonePlans: (planIds: number[], version: string) => {
      return dispatch(clonePlans({ planIds, version }));
    }
  };
};

interface ClonePlanRendererOwnProps extends ICellRendererParams {
  data: PlanMetadata,
  onClonePlan: () => void
}

const ClonePlanRenderer = (
  props: ClonePlanRendererOwnProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
) => {
  const {
    onClonePlan,
    data,
    clonePlans,
    publishVersions,
  } = props;
  const loading = !publishVersions;

  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState<undefined | string>(undefined);
  const [clonePlanLoading, setClonePlanLoading] = useState<boolean>(false);

  const logger = ServiceContainer.loggingService;

  // const handleEnterPress = useHandleKeyPress(handleSubmit);
  const versionDropdowns = usePublishVersionsToDropdowns(publishVersions);
  useSetSelectedStringVersion(versionDropdowns, setSelectedVersion, selectedVersion);
  const handleChangeVersion = useHandleChangeStringVersion(setSelectedVersion);
  const handleSubmit = useCallback(async () => {
    if (selectedVersion && typeof selectedVersion === 'string') {
      setClonePlanLoading(true)
      const clonePlamResp = await clonePlans([data.id], selectedVersion);

      if (clonePlamResp.type === 'workingSets/clonePlan/rejected') {
        toast.error('An error occured copying this plan', {
          position: toast.POSITION.TOP_LEFT
        });
        logger.error(`An error occured copying the plan id ${data.id} to ${selectedVersion}`);
        setClonePlanLoading(false);
      } else if (clonePlamResp.type === 'workingSets/clonePlan/fulfilled'){
        onClonePlan();
        setClonePlanLoading(false);
        setModalVisible(false);
      }
    }
  }, [selectedVersion, clonePlans, data.id, onClonePlan, logger]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);
  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const modal = !isModalVisible ? null :
    <div className="initialize-plan">
      <TitledModal
        title={'Copy Plan'}
        show={isModalVisible}
        data-qa={'copy-plan-modal'}
        className={'import-version-modal'}
      >
        <Modal.Body>
        {clonePlanLoading && <Overlay type="loading" visible={true} fitParent={true} />}
          <Segment>
            {/* eslint-disable-next-line max-len */}
            This action will copy the version &quot;<strong>{data.version.toLocaleUpperCase()}</strong>&quot; to &quot;<strong>{selectedVersion?.toLocaleUpperCase()}</strong>&quot;.  Do you wish to proceed?
          </Segment>
          <div className="dropdown-group">
            <Select
              fluid={true}
              loading={loading}
              data-qa="clone-plan-dropdown"
              icon={<i className="chevron far fa-chevron-down icon" />}
              options={versionDropdowns}
              value={selectedVersion}
              onChange={handleChangeVersion}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button content="Cancel" onClick={closeModal} />
          <Button
            content="Submit"
            className="import-version-modal-button"
            data-qa="copy-plan-btn-copy"
            onClick={handleSubmit}
          />
        </Modal.Footer>
      </TitledModal>
    </div>
    ;

  return (
    <React.Fragment>
      <Button
        icon="copy"
        color="teal"
        onClick={openModal}
      />
      {modal}
    </React.Fragment>);
};

export default connect(mapStateToProps, mapDispatchToProps)(ClonePlanRenderer);
