import { ColDef } from '@ag-grid-community/core';
import { get, isNil, inRange } from 'lodash';
import Highcharts, { Point } from 'highcharts';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import {
  VarianceBarTooltipProps,
  VariancePillProps,
  VariancePillVisualAttributes,
} from './TrendDetails';
import type { TrendSummaryConfig, VariancePillConfig } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import Renderer from 'src/utils/Domain/Renderer';
import { GRAY } from 'src/utils/Style/Theme';

export const POSITIVE_BAND = 0.011; // .011 since value is non-inclusive for inRange, so will accept 0.01 as 0
export const NEGATIVE_BAND = -0.01;
export function getBandedValue(value: number) {
  if (inRange(value, NEGATIVE_BAND, POSITIVE_BAND)) {
    return 0;
  }
  return value;
}

export function generateColumnDefs(columnsConfig: TenantConfigViewItem[]): ColDef[] {
  return columnsConfig.map((config) => {
    const baseDef: ColDef = {
      field: config.dataIndex,
      headerName: config.text,
      cellRenderer: config.renderer,
      width: get(config, 'width', 150),
      suppressMovable: true,
      suppressMenu: true,
    };
    if (config.xtype === 'treecolumn') {
      baseDef.rowGroup = true;
      baseDef.hide = true;
    }

    const cellRendererParams = get(config, 'rendererParams', null);
    return isNil(cellRendererParams)
      ? baseDef
      : {
        ...baseDef,
        cellRendererParams,
      };
  });
}

export const getChartOptions = (
  data: Highcharts.PointOptionsObject[],
  tooltip: VarianceBarTooltipProps
): Highcharts.Options => ({
  title: {
    text: undefined,
  },
  chart: {
    inverted: true,
    type: 'bullet',
    // height needs to be slightly less than ag-grid row height
    height: 70,
    // width needs to be just over half the width of the ag-grid row width
    width: 225,
    marginBottom: 1,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    title: {
      text: undefined,
    },
    labels: {
      formatter: () => '', // hides axis numbers
    },
    gridLineColor: '#EFF0F0',
    max: 1, // stops grid lines at 100%
  },
  series: [
    {
      type: 'bullet',
      data,
    },
  ],
  plotOptions: {
    bullet: {
      targetOptions: {
        color: '#000',
        width: 15, // target value line width
      },
      pointWidth: 8, // y value line width
    },
    series: {
      borderWidth: 0,
    },
  },
  tooltip: {
    outside: true,
    shape: 'square',
    hideDelay: 0,
    headerFormat: `<div style="margin-bottom: 0.5rem">${tooltip.header}</div>`,
    pointFormatter: function (this: Point) {
      const renderedValue = Renderer[tooltip.renderer](this.y);
      // @ts-ignore
      const renderedTarget = Renderer[tooltip.renderer](this.target);
      return `${tooltip.value}: <b>${renderedValue}</b> </br>${tooltip.target}: <b>${renderedTarget}</b>`;
    },
    valueSuffix: `${tooltip.dataSuffix}`,
    useHTML: true,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
});

export function generatePillRendererProps(data: any, pillsConfig: VariancePillConfig[]): VariancePillProps[] {
  // pill number values
  return pillsConfig.map((pill) => {
    const primaryValue = pill.valueDataIndex ? get(data, pill.valueDataIndex, null) : null;
    const primaryVariance = pill.varianceDataIndex ? get(data, pill.varianceDataIndex, null) : null;
    // pill rendered values
    // default to non-breaking space if number isn't a finite number (not nan, null, etc.)
    const primaryText = pill.text;
    const primaryValueText = Number.isFinite(primaryValue) ? Renderer['usMoneyAbbreviated'](primaryValue) : '\u00A0';
    const primaryVarianceText = Number.isFinite(primaryVariance)
      ? Renderer['simplePercent'](primaryVariance)
      : '\u00A0';
    return {
      text: primaryText,
      value: primaryValue,
      valueText: primaryValueText,
      variance: primaryVariance,
      varianceText: primaryVarianceText,
    };
  });
}

export function getPillVisualAttributes(variance: number | null): VariancePillVisualAttributes | null {
  if (isNil(variance)) {
    return null;
  }
  if (getBandedValue(variance) === 0) {
    return {
      color: GRAY,
      icon: 'fas fa-minus',
    };
  } else if (variance >= POSITIVE_BAND) {
    return {
      color: '#6BB08B',
      icon: 'fas fa-caret-up',
    };
  } else {
    return {
      color: '#D04B32',
      icon: 'fas fa-caret-down',
    };
  }
}
