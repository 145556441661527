import { createSelector } from 'reselect';
import { parseGridConfig, FrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';
import { AppState } from 'src/store';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { filterData } from 'src/utils/Pivot/Filter';
import { SubheaderSlice, GroupBySlice } from 'src/components/Subheader/Subheader.slice';
import {
  CategorySummaryViewData,
  CategorySummarySlice,
} from 'src/pages/Hindsighting/CategoryRecap/CategorySummary/CategorySummary.slice';
import { Renderer } from 'src/utils/Domain/Renderer';
import { identity } from 'fp-ts/lib/function';
import { TenantConfigViewItem, TenantConfigViewData, isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { MacrosProps } from 'src/common-ui/components/Macros/Macros.types';
import { pick, isNil, isEmpty } from 'lodash';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ColDef } from '@ag-grid-community/core';
import { listDataTreeToAgFlatTree } from 'src/utils/Component/AgGrid/AgDataFormat';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { CategorySummaryOwnProps } from './CategorySummary.container';
import { getDataFromCache, isDataLoaded } from 'src/services/pivotServiceCache';
import { ViewDataState } from 'src/types/Domain';

export interface StateSelection extends CategorySummarySlice, CategorySummaryViewData {
  configLoaded: boolean;
  dataLoaded: boolean;
  subheader: SubheaderSlice;
  title: string;
}

function selectState(state: AppState, { title }: CategorySummaryOwnProps): StateSelection {
  const viewState = state.pages.hindsighting.categoryRecap.categorySummary;
  const { viewDefnState, viewDataState } = viewState;
  const treeData = getDataFromCache(viewState)?.tree || [];
  const configLoaded = isViewDefnLoaded(viewDefnState);
  const dataLoaded = isDataLoaded(viewDataState);

  return {
    title,
    configLoaded,
    dataLoaded,
    subheader: state.subheader,
    keyFinancials: treeData,
    choiceProductivity: treeData,
    gridData: !isEmpty(treeData) ? treeData[0].children : treeData,
    ...state.pages.hindsighting.categoryRecap.categorySummary,
  };
}

export interface LoadingProjection {
  title: string;
  viewDataState: ViewDataState;
  configLoaded: false;
  dataLoaded: false;
  groupBy: GroupBySlice;
  gridData: BasicPivotItem[];
  colDefs: ColDef[];
}

export interface LoadedProjection {
  title: string;
  viewDataState: ViewDataState;
  configLoaded: boolean;
  dataLoaded: boolean;
  rowHeight?: number;
  groupBy: GroupBySlice;
  keyFinancials: MacrosProps[];
  choiceProductivity: MacrosProps[];
  treeColumnDefinition: ColDef | undefined;
  gridData: BasicPivotItem[];
  colDefs: ColDef[];
  frameworkComponents: FrameworkComponents;
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
  isPrintMode?: boolean;
}

export type StateProjection = LoadedProjection | LoadingProjection;

function defnToRenderer(defn: TenantConfigViewItem) {
  return defn.renderer ? Renderer[defn.renderer] : identity;
}

function viewItemToFinancialProps(viewItem: TenantConfigViewItem, data: BasicPivotItem): MacrosProps {
  if (viewItem.view) {
    const secondaryDefVariant = viewItem.view[0];
    const secondaryDefMetric = viewItem.view[1];

    const primaryRenderer = defnToRenderer(viewItem);
    const variantRenderer = defnToRenderer(secondaryDefVariant);
    const secondaryRenderer = defnToRenderer(secondaryDefMetric);

    const primaryValue = data && data[viewItem.dataIndex];
    const variantValue = data && data[secondaryDefVariant.dataIndex];
    const secondaryValue = data && data[secondaryDefMetric.dataIndex];
    const direction = variantValue === 0 ? 'neutral' : variantValue > 0 ? 'up' : 'down';

    return {
      metrics: {
        primary: { rendered: primaryRenderer(primaryValue), label: viewItem.text },
        directional: { rendered: variantRenderer(variantValue), direction },
        secondary: { rendered: secondaryRenderer(secondaryValue), label: secondaryDefMetric.text },
      },
      extraClasses: '',
      dataLoaded: true,
    };
  } else {
    return {
      metrics: {
        primary: { rendered: '', label: '' },
        directional: { rendered: '', direction: 'neutral' },
        secondary: { rendered: '', label: '' },
      },
      extraClasses: '',
      dataLoaded: false,
    };
  }
}

export function projectState(stateSelection: StateSelection): StateProjection {
  const {
    title,
    configLoaded,
    dataLoaded,
    subheader,
    viewDefns,
    keyFinancials,
    gridData,
    choiceProductivity,
    viewDataState,
  } = stateSelection;

  if (configLoaded && viewDefns) {
    const productRootData = keyFinancials[0];

    const finalKeyFinancials = viewDefns.keyFinancials.view.map((viewItem) => {
      return viewItemToFinancialProps(viewItem, productRootData);
    });

    const finalChoiceProductivity = viewDefns.choiceProductivity.view.map((viewItem) => {
      return viewItemToFinancialProps(viewItem, choiceProductivity[0]);
    });

    let gridItems;
    let treeColumnDefinition;
    let groupingField: string | undefined;

    const { search, groupBy } = subheader;

    // Subheader Group By
    if (viewDefns.groupByDefn && groupBy.selection) {
      groupingField = getGroupBySelectedOptionProperty(groupBy, 'dataIndex');
      const result = listDataTreeToAgFlatTree([groupingField], search, [], gridData);
      treeColumnDefinition = result.treeColumnDefinition;
      gridItems = result.agFlatTree;
    } else {
      // No Group By
      let searchFields = externalGridSearchFields;
      if (viewDefns && viewDefns.gridDefn.searchIndexes) {
        searchFields = viewDefns.gridDefn.searchIndexes;
      }
      gridItems = filterData(gridData, search, searchFields, []);
    }

    if (isNil(groupingField)) {
      groupingField = getGroupBySelectedOptionProperty(groupBy, 'dataIndex');
    }

    const parseResult = pick(
      parseGridConfig(viewDefns.gridDefn, groupingField),
      'colDefs',
      'frameworkComponents',
      'rowHeight'
    );

    const projection: StateProjection = {
      viewDataState,
      title,
      configLoaded,
      dataLoaded,
      groupBy: groupBy,
      treeColumnDefinition,
      gridData: gridItems,
      ...parseResult,
      keyFinancials: finalKeyFinancials,
      choiceProductivity: finalChoiceProductivity,
      viewDefn: viewDefns.gridDefn,
      unmodifiedViewDefn: viewDefns.unmodifiedViewDefn,
    };

    return projection;
  }

  const unloadedProjection: LoadingProjection = {
    viewDataState,
    title,
    configLoaded: false,
    dataLoaded: false,
    groupBy: subheader.groupBy,
    gridData: [],
    colDefs: [],
  };

  return unloadedProjection;
}

export default createSelector(selectState, projectState);
